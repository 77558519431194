import project01 from './2017-06_ExpensePlatform';
import project02 from './2020-06_Chatbots';

const projects = [
  project01,
  project02,
]

projects.reverse();

export default projects;
