import project01 from './2019-12_HTMLWebsite';
// import project02 from './2020-10_GameProject';
// import project03 from './2021-01_MusicAnalyzer';
import project04 from './2021-03_Gdesiio';
import project05 from './2021-11_SeanMarjasoncom';

const projects = [
  project01,
  // project02,
  // project03,
  project04,
  project05,
]

projects.reverse();

export default projects;
